import { Button, styled, Typography } from "@mui/material";
import React from "react";
import bgImage from "../assets/images/hero-bg.png";
import { Container as MuiContainer } from "@mui/material";
import { ReactComponent as HeroGraphic } from "../assets/svgs/hero-graphic.svg";
import { Link } from "react-router-dom";
import routes from "../navigation/routes";

interface ContainerProps {
  backgroundImage: string;
}

const Container = styled("div")<ContainerProps>(
  ({ theme, backgroundImage }) => ({
    backgroundImage: `url(${backgroundImage})`,
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    overflowX: "hidden",
    [theme.breakpoints.down("laptop")]: {
      height: "80vh",
    },
    [theme.breakpoints.down("tablet")]: {
      height: "42vh",
      justifyContent: "flex-end",
      padding: "0 2.4rem 4rem 4rem",
    },
  })
);

const HeroTitle = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontWeight: 700,
  fontSize: "5.6rem",
  lineHeight: "6.7rem",
  letterSpacing: "-0.05em",
  [theme.breakpoints.down("laptop")]: {
    fontSize: "4.8rem",
    lineHeight: "5.8rem",
  },
  [theme.breakpoints.down("tablet")]: {
    fontSize: "2.4rem",
    lineHeight: "2.9rem",
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  marginTop: "2rem",
  color: "#fff",
  fontFamily: "DIN-Regular",
  fontSize: "2rem",
  lineHeight: "2.7rem",
  [theme.breakpoints.down("laptop")]: {
    fontSize: "1.6rem",
    lineHeight: "2.2rem",
  },
  [theme.breakpoints.down("tablet")]: {
    marginTop: "1.2rem",
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const GradientText = styled("span")(({ theme }) => ({
  background: "linear-gradient(89.87deg, #FAA719 14.26%, #F57F20 51.73%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

const ConnectButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  color: "#fff",
  background: `linear-gradient(111.32deg, ${theme.palette.primary.main} -11.69%, ${theme.palette.primary.main} 103.22%)`,
  marginTop: "4.8rem",
  transition: "all 0.2s",
  "&:hover": {
    "&.MuiButton-root": {
      transform: "scale(1.02)",
      background:
        "linear-gradient(111.32deg, #FBBE4A -11.69%, #F57F20 103.22%)",
    },
  },
  "&:active": {
    "&.MuiButton-root": {
      background: `linear-gradient(111.32deg, ${theme.palette.primary.main} -11.69%, ${theme.palette.primary.main} 103.22%)`,
      transform: "scale(1.0)",
    },
  },
  [theme.breakpoints.down("laptop")]: {
    marginTop: "5rem",
  },
  [theme.breakpoints.down("tablet")]: {
    marginTop: "1.4rem",
    padding: "0.65rem 1.6rem",
  },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Medium",
  fontSize: "1.8rem",
  lineHeight: "2.4rem",
  letterSpacing: "-0.005em",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const NavigationLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const Graphic = styled(HeroGraphic)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  [theme.breakpoints.down("laptop")]: {
    left: "-7rem",
    width: "auto",
    height: "30rem",
    "& path": {
      strokeWidth: 3,
    },
  },
  [theme.breakpoints.down("tablet")]: {
    left: "-3.8rem",
    width: "auto",
    height: "16.4rem",
    "& path": {
      strokeWidth: 3.5,
    },
  },
}));

const Hero = () => {
  return (
    <Container backgroundImage={bgImage}>
      <Graphic />
      <MuiContainer fixed>
        <HeroTitle variant="h1">
          Get <GradientText>solar panels</GradientText> for the roofs.
          <br />
          The sun won't let you down.
        </HeroTitle>
        <HeroSubtitle variant="h6">
          Bring the change, join the energy revolution now!
        </HeroSubtitle>
        <NavigationLink to={routes.INQUIRY}>
          <ConnectButton color="primary" variant="contained">
            <ButtonText>Let's Connect</ButtonText>
          </ConnectButton>
        </NavigationLink>
      </MuiContainer>
    </Container>
  );
};

export default Hero;

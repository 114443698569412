import { Container, styled, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import theme from "../theme/theme";
import {
  companyDetails,
  providedFacilities,
} from "../utils/constants/companyDetails";
import { ReactComponent as SolarPanel } from "../assets/svgs/solar-panel.svg";
import { linkConfig } from "../Elements/Navbar";

const PageContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));

const Wrapper = styled(Container)(({ theme }) => ({
  padding: "8% 0",
  [theme.breakpoints.down("tablet")]: {
    padding: "8% 2.4rem",
  },
}));

const InfoBar = styled("div")(({ theme }) => ({
  margin: "0 9.6rem",
  padding: "3.2rem 4rem",
  position: "absolute",
  left: 0,
  right: 0,
  top: "-7rem",
  zIndex: 5,
  background: "#FFFFFF",
  border: "1px solid #E5EBEE",
  boxShadow: "0px 4px 20px rgba(13, 71, 161, 0.06)",
  borderRadius: 8,
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("laptop")]: {
    margin: "0 2.4rem",
    padding: "3.2rem",
  },
  [theme.breakpoints.down("tablet")]: {
    position: "initial",
    padding: "2rem",
    flexWrap: "wrap",
    gap: "2.4rem",
    margin: "0",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "3.6rem",
  lineHeight: "4.3rem",
  letterSpacing: "-0.04em",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("laptop")]: {
    marginTop: "6.4rem",
  },
  [theme.breakpoints.down("tablet")]: {
    marginTop: "3.2rem",
    fontSize: "2rem",
    lineHeight: "2.4rem",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Regular",
  fontSize: "2rem",
  lineHeight: "2.7rem",
  color: theme.palette.secondary.main,
  marginTop: "1.2rem",
  marginBottom: "6rem",
  [theme.breakpoints.down("tablet")]: {
    marginTop: "0.6rem",
    marginBottom: "2.4rem",
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const DetailContainer = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

const DetailTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Regular",
  fontSize: "2rem",
  lineHeight: "2.7rem",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("laptop")]: {
    fontSize: "1.6rem",
    lineHeight: "2.2rem",
  },
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const DetailSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "4rem",
  lineHeight: "4.6rem",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("laptop")]: {
    fontSize: "3.2rem",
    lineHeight: "3.7rem",
  },
  [theme.breakpoints.down("tablet")]: {
    fontSize: "2.4rem",
    lineHeight: "2.9rem",
  },
}));

interface TabProps {
  active: boolean;
}

const TabContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "4rem",
}));

const TabDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
  gap: "5rem",
  marginTop: "6.8rem",
  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("tablet")]: {
    marginTop: "0",
    gap: "2.4rem",
  },
}));

const TabLabel = styled(Typography)<TabProps>(({ theme, active }) => ({
  fontFamily: "DIN-Regular",
  fontSize: "2rem",
  lineHeight: "2.8rem",
  color: active ? theme.palette.primary.main : "#6B787E",
  padding: "0.8rem 1.2rem",
  borderBottom: active ? `1px solid ${theme.palette.primary.main}` : "none",
  cursor: "pointer",
}));

const TabDetailContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "2.8rem",
  order: 1,
  [theme.breakpoints.down("laptop")]: {
    order: 2,
  },
  [theme.breakpoints.down("tablet")]: {
    gap: "1.6rem",
  },
}));

const TabImage = styled("img")(({ theme }) => ({
  borderRadius: 8,
  flex: 1,
  width: "47.8rem",
  height: "auto",
  objectFit: "cover",
  order: 2,
  [theme.breakpoints.down("laptop")]: {
    width: "100%",
    order: 1,
  },
}));

const TabDetailTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "2.8rem",
  lineHeight: "3.2rem",
  letterSpacing: "-0.02em",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("tablet")]: {
    fontSize: "2rem",
    lineHeight: "2.3rem",
  },
}));

const SolarPanelIcon = styled(SolarPanel)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "3.2rem",
    height: "auto",
  },
}));

const TabDetailDescription = styled(Typography)(({ theme }) => ({
  marginTop: "1.6rem",
  fontFamily: "DIN-Regular",
  fontSize: "2rem",
  lineHeight: "2.7rem",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("tablet")]: {
    marginTop: "1.2rem",
    marginBottom: "3.2rem",
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const CompanyDetails = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tablet = useMediaQuery(() => theme.breakpoints.down("laptop"));

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <PageContainer ref={linkConfig[0].ref}>
      <Wrapper fixed>
        <InfoBar>
          {companyDetails.map((detail) => (
            <DetailContainer key={detail.key}>
              <DetailTitle>{detail.title}</DetailTitle>
              <DetailSubtitle>{detail.value}</DetailSubtitle>
            </DetailContainer>
          ))}
        </InfoBar>
        <Title variant="h2">What do we offer</Title>
        <Subtitle variant="h6">
          Tracksun offers a variety of services in both B2B and B2C markets
        </Subtitle>
        {!tablet ? (
          <React.Fragment>
            <TabContainer>
              {providedFacilities.map((item) => (
                <TabLabel
                  key={item.key}
                  onClick={() => handleTabClick(item.key)}
                  active={item.key === activeIndex}
                >
                  {item.tabTitle}
                </TabLabel>
              ))}
            </TabContainer>
            <TabDetailsContainer>
              <TabDetailContainer>
                <SolarPanelIcon />
                <React.Fragment>
                  <TabDetailTitle variant="h4">
                    {providedFacilities[activeIndex].title}
                  </TabDetailTitle>
                  <TabDetailDescription variant="body1">
                    {providedFacilities[activeIndex].description}
                  </TabDetailDescription>
                </React.Fragment>
              </TabDetailContainer>
              <TabImage
                src={providedFacilities[activeIndex].image}
                alt={providedFacilities[activeIndex].tabTitle}
              />
            </TabDetailsContainer>
          </React.Fragment>
        ) : (
          providedFacilities.map((item) => (
            <TabDetailsContainer key={item.key}>
              <TabDetailContainer>
                <SolarPanelIcon />
                <React.Fragment>
                  <TabDetailTitle variant="h4">
                    {providedFacilities[activeIndex].title}
                  </TabDetailTitle>
                  <TabDetailDescription variant="body1">
                    {providedFacilities[activeIndex].description}
                  </TabDetailDescription>
                </React.Fragment>
              </TabDetailContainer>
              <TabImage src={item.image} alt={item.tabTitle} />
            </TabDetailsContainer>
          ))
        )}
      </Wrapper>
    </PageContainer>
  );
};

export default CompanyDetails;

import { Container, IconButton, styled, Typography } from "@mui/material";
import React, { FC } from "react";
import bgImage from "../assets/images/footer-bg.png";
import { Subtitle } from "../pages/CompanyDetails";
import ContactForm from "./ContactForm";
import { ReactComponent as Logo } from "../assets/svgs/footer-logo.svg";
import { ReactComponent as Facebook } from "../assets/svgs/social_facebook.svg";
import { ReactComponent as LinkedIn } from "../assets/svgs/social_linkedin.svg";
import { ReactComponent as Twitter } from "../assets/svgs/social_twitter.svg";
import EmailForm from "./EmailForm";

interface ContainerProps {
  backgroundImage: string;
  showForm?: boolean;
}

const Wrapper = styled(Container)(({ theme }) => ({
  padding: "6% 0",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "4rem",
  lineHeight: "5.4rem",
  letterSpacing: "-0.005em",
  color: "#fff",
  textAlign: "center",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "2rem",
    lineHeight: "2.7rem",
  },
}));

const Subheading = styled(Subtitle)(({ theme }) => ({
  marginTop: "1.2rem",
  marginBottom: "3.2rem",
  color: "#fff",
  textAlign: "center",
  [theme.breakpoints.down("tablet")]: {},
}));

const PageContainer = styled("div")<ContainerProps>(
  ({ theme, backgroundImage, showForm }) => ({
    backgroundImage: ` linear-gradient(180deg, rgba(0, 48, 73, 0.15) 0%, #003049 90.1%), url(.jpg), url(${backgroundImage})`,
    width: "100vw",
    minHeight: showForm ? "100vh" : "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("tablet")]: {
      padding: "2.4rem",
    },
  })
);

const BrandLogo = styled(Logo)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "8.4rem",
    height: "auto",
  },
}));

interface IFooterProps {
  showForm?: boolean;
}

const FooterWrapper = styled("footer")<IFooterProps>(({ theme, showForm }) => ({
  padding: showForm ? "2.4rem 9.6rem" : "12rem 9.6rem 2.4rem 9.6rem",
  [theme.breakpoints.down("laptop")]: {
    padding: "2.4rem 6.4rem",
    paddingTop: showForm ? "2.4rem" : "4.8rem",
  },
  [theme.breakpoints.down("tablet")]: {
    padding: "1.6rem 2rem",
  },
}));

const InfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "3.6rem",
  },
}));

const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  [theme.breakpoints.down("tablet")]: {
    gap: "1.6rem",
  },
}));

const SocialContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));

const Location = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Regular",
  fontSize: "1.8rem",
  lineHeight: "2.4rem",
  letterSpacing: "0.004em",
  color: "#FFFFFF",
  width: "20%",
  [theme.breakpoints.down("laptop")]: {
    width: "40%",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
}));

const CopyrightText = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Regular",
  fontSize: "1.6rem",
  lineHeight: "2.2rem",
  letterSpacing: "-0.005em",
  color: "#FFFFFF",
  opacity: 0.6,
  textAlign: "center",
  marginTop: "6.2rem",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1rem",
    lineHeight: "1.4rem",
  },
}));

interface IProps {
  showForm?: boolean;
}

const Footer: FC<IProps> = ({ showForm = true }) => {
  return (
    <PageContainer backgroundImage={bgImage} showForm={showForm}>
      {showForm && (
        <Wrapper fixed>
          <Heading>Talk with us</Heading>
          <Subheading>
            Lets create a better environment through solar energy
          </Subheading>
          <EmailForm />
        </Wrapper>
      )}
      <FooterWrapper>
        <InfoContainer>
          <LeftContainer>
            <BrandLogo />
            <SocialContainer>
              <SocialButton>
                <LinkedIn />
              </SocialButton>
              <SocialButton>
                <Twitter />
              </SocialButton>
              <SocialButton>
                <Facebook />
              </SocialButton>
            </SocialContainer>
          </LeftContainer>
          <Location>
            19, Radhe krishna estate, Nr Global estate, road no 5 Kathwada GIDC,
            Kathwada, Ahmedabad, Gujarat - 382430
          </Location>
        </InfoContainer>
        <CopyrightText>© Copyright 2022. All Rights Reserved.</CopyrightText>
      </FooterWrapper>
    </PageContainer>
  );
};

export default Footer;

import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#F57F20",
      },
      secondary: {
        main: "#003049",
      },
      text: {
        primary: "#003049",
        secondary: "#6B787E",
      },
      background: {
        paper: "transparent",
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: '"Ubuntu", sans-serif',
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "5rem",
            padding: "1.2rem 1.6rem",
            fontSize: "1.6rem",
            textTransform: "none",
          },
        },
      },
    },
    spacing: [0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 64, 80, 96, 160],
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },
    },
  })
);

export default theme;

import facility0 from "../../assets/images/facilities/facility-0.png";
import facility1 from "../../assets/images/facilities/facility-1.png";
import facility2 from "../../assets/images/facilities/facility-2.png";
import facility3 from "../../assets/images/facilities/facility-3.png";

export const companyDetails = [
  {
    key: 1,
    title: "Installed Capacity",
    value: "4.5+ MW",
  },
  {
    key: 2,
    title: "WTGS Installed",
    value: "380+",
  },
  {
    key: 3,
    title: "Satisfied customers",
    value: "450+",
  },
  {
    key: 4,
    title: "Years of Experience",
    value: "5+",
  },
];

export const providedFacilities = [
  {
    key: 0,
    image: facility0,
    tabTitle: "Rooftop Solar",
    title: "Rooftop Solar Installation",
    description: `We believe in making the complex powerfully simple—from system design to customer service, we undertake turnkey projects across the life of the plant; ensuring our clients have all the resources they need for a bankable project.`,
  },
  {
    key: 1,
    image: facility1,
    tabTitle: "Commercial Solar",
    title: "Commercial Solar Solutions",
    description: `Our experts do the legwork. From designing the system to handover, we represent a safe investment and guaranteed results. We provide you with customized solutions, unmatched in terms of quality and savings potential.
      We promise to make solar a hassle-free purchase so that you enjoy its benefits for 25 years.
      `,
  },
  {
    key: 2,
    image: facility2,
    tabTitle: "Ground Mounted Solar",
    title: "Ground Mounted Solar",
    description: `Solar can drastically reduce or even eliminate energy bills effectively lowering overhead. Solar energy provides a good return on investment through the use of un-utilized rooftop space or Land and requires minimal maintenance.`,
  },
  {
    key: 3,
    image: facility3,
    tabTitle: "EPC Projects",
    title: "EPC Projects",
    description: `Our Solar team ensures your photovoltaic projects are constructed cost-effectively, operate flawlessly and can provide regular operation and maintenance for optimal power generation.
    Tracksun offers a single source for all development and project activities, from planning and design through to project construction and connection to the grid as well as maintenance and care of the complete solar power plants.
      `,
  },
];

import { styled } from "@mui/material";
import React, { Suspense } from "react";
import Hero from "./Hero";

const CompanyDetails = React.lazy(() => import("./CompanyDetails"));
const WhoWeAre = React.lazy(() => import("./WhoWeAre"));
const Testimonials = React.lazy(() => import("./Testimonials"));
const RecentWorks = React.lazy(() => import("./RecentWorks"));
const Growth = React.lazy(() => import("./Growth"));
const Footer = React.lazy(() => import("../Elements/Footer"));

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <Suspense fallback={<div>Loading...</div>}>
        <CompanyDetails />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <WhoWeAre />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Testimonials />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <RecentWorks />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Growth />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </React.Fragment>
  );
};

export default Home;

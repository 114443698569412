import {
  Button,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { RefObject, useState } from "react";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as Phone } from "../assets/svgs/phone.svg";
import { ReactComponent as Menu } from "../assets/svgs/menu.svg";
import { ReactComponent as Close } from "../assets/svgs/close.svg";

import theme from "../theme/theme";
import routes from "../navigation/routes";

interface HeaderProps {
  show: boolean;
}

const Header = styled("header")<HeaderProps>(({ theme, show }) => ({
  margin: "2.4rem 9.6rem",
  padding: "2rem",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: show ? 0 : 8,
  position: "fixed",
  backgroundColor: "#fff",
  left: 0,
  right: 0,
  zIndex: 10,
  transition: "all 0.2s",
  [theme.breakpoints.down("laptop")]: {
    margin: show ? "0" : "2.4rem 3.2rem",
    flexDirection: "column",
    alignItems: "start",
    padding: "2.4rem 4rem",
  },
  [theme.breakpoints.down("tablet")]: {
    margin: show ? "0" : "1.6rem 2rem",
    padding: "1.6rem 2rem",
  },
}));

const BrandLogo = styled(Logo)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "8.4rem",
    height: "auto",
  },
}));

const PhoneIcon = styled(Phone)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "2rem",
    height: "auto",
  },
}));

const CloseIcon = styled(Close)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "1.8rem",
    height: "auto",
  },
}));

const MenuIcon = styled(Menu)(({ theme }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "1.8rem",
    height: "auto",
  },
}));

const LinksContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  gap: "3.2rem",
  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
    alignItems: "start",
  },
}));

const Link = styled(Typography)(({ theme }) => ({
  fontSize: "1.6rem",
  lineHeight: "2.2rem",
  letterSpacing: "-0.005em",
  fontFamily: "DIN-Regular",
  color: theme.palette.secondary.main,
  cursor: "pointer",
  borderBottom: `1px solid transparent`,
  transition: "all 0.3s",
  "&:hover": {
    fontFamily: "DIN-Medium",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.down("laptop")]: {
    fontSize: "2.4rem",
    lineHeight: "4.3rem",
    letterSpacing: "-0.025em",
  },
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.6rem",
    lineHeight: "2.2rem",
    letterSpacing: "-0.005em",
  },
}));

const ContactButton = styled(Button)(({ theme }) => ({
  fontFamily: "DIN-Medium",
  border: `1.5px solid transparent`,
  "&:hover": {
    "&.MuiButton-root": {
      backgroundColor: "#fff",
      border: `1.5px solid ${theme.palette.secondary.main}`,
    },
    "& > *": {
      color: theme.palette.secondary.main,
    },
    "& svg path": {
      fill: theme.palette.secondary.main,
    },
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Medium",
  fontSize: "1.6rem",
  lineHeight: "2.2rem",
  letterSpacing: "-0.005em",
}));

const MenuContainer = styled("div")<HeaderProps>(({ theme, show }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all 0.2s",
  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: "6rem",
    marginTop: "4.8rem",
    visibility: show ? "visible" : "hidden",
    display: show ? "flex" : "none",
    opacity: show ? 1 : 0,
  },
  [theme.breakpoints.down("tablet")]: {
    gap: "4.2rem",
    marginTop: "2.4rem",
  },
}));

const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("laptop")]: {
    width: "100%",
  },
}));

const BrandLogoLink = styled("a")(({}) => ({}));

export const linkConfig = [
  { key: 1, label: "Services", ref: React.createRef<any>(), top: 200 },
  { key: 2, label: "Who we are", ref: React.createRef<any>(), top: 0 },
  { key: 3, label: "Testimonials", ref: React.createRef<any>(), top: 120 },
  { key: 4, label: "Gallery", ref: React.createRef<any>(), top: 120 },
];

const Navbar = () => {
  const tablet = useMediaQuery(() => theme.breakpoints.down("laptop"));
  const mobile = useMediaQuery(() => theme.breakpoints.down("tablet"));
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const phoneNumber = "+917990598471";

  const scrollToDiv = (scrollRef: RefObject<any>, top: number) => {
    if (!scrollRef.current) return;
    window.scrollTo({
      behavior: "smooth",
      top: scrollRef.current.getBoundingClientRect().top + window.scrollY - top,
    });
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleCall = () => {
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <Header show={showMenu}>
      <LogoContainer>
        <BrandLogoLink href={routes.HOME}>
          <BrandLogo />
        </BrandLogoLink>
        {tablet ? (
          showMenu ? (
            <IconButton onClick={handleShowMenu}>
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleShowMenu}>
              <MenuIcon />
            </IconButton>
          )
        ) : (
          <></>
        )}
      </LogoContainer>
      <MenuContainer show={showMenu}>
        <LinksContainer>
          {linkConfig.map((link, index) => (
            <Link
              key={link.key}
              onClick={() => scrollToDiv(link.ref, link.top)}
            >
              {link.label}
            </Link>
          ))}
        </LinksContainer>
        <ContactButton
          variant="contained"
          color="secondary"
          startIcon={<PhoneIcon />}
          onClick={handleCall}
        >
          <Text>{phoneNumber}</Text>
        </ContactButton>
      </MenuContainer>
    </Header>
  );
};

export default Navbar;

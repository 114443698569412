import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../Elements/Navbar";
import InquiryPage from "../pages/InquiryPage";
import Home from "../pages/Home";
import ScrollToTop from "../utils/hooks/ScrollToTop";
import routes from "./routes";

const AppRoutes = () => {
  return (
    <Router>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.INQUIRY} element={<InquiryPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default AppRoutes;

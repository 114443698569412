import { Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Subtitle } from "./CompanyDetails";
import { ReactComponent as Phone } from "../assets/svgs/phone.svg";
import { ReactComponent as Email } from "../assets/svgs/email.svg";
import { ReactComponent as Back } from "../assets/svgs/back.svg";
import Footer from "../Elements/Footer";
import { useNavigate } from "react-router";
import EmailForm from "../Elements/EmailForm";

const Wrapper = styled(Container)(({ theme }) => ({
  padding: "8% 0",
  [theme.breakpoints.down("laptop")]: {
    paddingTop: "20%",
  },
  [theme.breakpoints.down("tablet")]: {
    padding: "8% 2.4rem",
    paddingTop: "24%",
  },
}));

const Row = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: "4rem",
  marginTop: "1.6rem",
  [theme.breakpoints.down("laptop")]: {
    flexDirection: "column",
  },
}));

const ContactDetails = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4.8rem",
  flex: 1,
  [theme.breakpoints.down("laptop")]: {
    gap: "2.8rem",
  },
  [theme.breakpoints.down("tablet")]: {
    gap: "1.6rem",
  },
}));

const GradientText = styled("span")(({ theme }) => ({
  background: "linear-gradient(273.25deg, #F57F20 0.48%, #FBBE4A 105.15%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 500,
  fontSize: "1.4rem",
  lineHeight: "1.9rem",
  letterSpacing: "0.16em",
  textTransform: "uppercase",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "4.8rem",
  lineHeight: "5.8rem",
  letterSpacing: "-0.04em",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("laptop")]: {
    fontSize: "4rem",
    lineHeight: "4.8rem",
    width: "80%",
  },
  [theme.breakpoints.down("tablet")]: {
    fontSize: "2.4rem",
    lineHeight: "2.9rem",
    width: "95%",
  },
}));

const GradientSpan = styled("span")(({ theme }) => ({
  background: "linear-gradient(89.87deg, #F57F20 14.26%, #FAA719 51.73%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

const Subline = styled(Subtitle)(({ theme }) => ({
  marginBottom: 0,
}));

const TextButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
}));

const PhoneIcon = styled(Phone)(({ theme }) => ({
  width: 32,
  height: 32,
  "& path": {
    fill: theme.palette.secondary.main,
  },
  [theme.breakpoints.down("laptop")]: {
    width: 24,
    height: 24,
  },
}));

const EmailIcon = styled(Email)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    width: 24,
    height: 24,
  },
}));

const BackIcon = styled(Back)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    width: 24,
    height: 24,
  },
}));

const Column = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const InquiryPage = () => {
  const phoneNumber = "+917990598471";
  const email = "contact@tracksun.com";

  const navigate = useNavigate();

  const handleCall = () => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleMail = () => {
    window.open(`mailto:${email}`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <Wrapper fixed>
        <TextButton
          startIcon={<BackIcon />}
          variant="text"
          color="secondary"
          onClick={handleGoBack}
        >
          Home
        </TextButton>
        <Row>
          <ContactDetails>
            <GradientText>Contact Us</GradientText>
            <div>
              <Title variant="h1">
                Get <GradientSpan>solar solution</GradientSpan> of your need.
                Let's be in touch.
              </Title>
              <Subline>
                Bring the change, join the energy revolution now!
              </Subline>
            </div>
            <Column>
              <TextButton
                startIcon={<PhoneIcon />}
                variant="text"
                color="secondary"
                onClick={handleCall}
              >
                {phoneNumber}
              </TextButton>
              <TextButton
                startIcon={<EmailIcon />}
                variant="text"
                color="secondary"
                onClick={handleMail}
              >
                {email}
              </TextButton>
            </Column>
          </ContactDetails>
          <EmailForm enquiryPage={true} />
        </Row>
      </Wrapper>
      <Footer showForm={false} />
    </React.Fragment>
  );
};

export default InquiryPage;

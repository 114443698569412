import { Button, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const Container = styled("form")(({ theme, enquiryPage }) => ({
  background: "#FFFFFF",
  border: "1px solid #DEE7F3",
  boxShadow: "0px 4px 20px rgba(0, 48, 73, 0.14)",
  borderRadius: 12,
  padding: "4.8rem 6rem",
  // margin: enquiryPage ? 0 : "0 6%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  gap: "4.8rem",
  [theme.breakpoints.down("laptop")]: {
    margin: "0",
    padding: "4.8rem 3.6rem",
  },
  [theme.breakpoints.down("tablet")]: {
    padding: "2.4rem 4rem",
    gap: "3.2rem",
  },
}));

const InputField = styled(TextField)(({ theme }) => ({
  "& label": {
    fontWeight: 400,
    fontSize: "2.4rem",
    lineHeight: "2.7rem",
    letterSpacing: "-0.005em",
    color: theme.palette.secondary.main,
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& .MuiInputBase-input": {
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
    fontWeight: 500,
    color: theme.palette.secondary.main,
    "&::placeholder": {
      fontWeight: 400,
    },
  },
  [theme.breakpoints.down("tablet")]: {
    "& label": {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
    },
  },
}));

const CheckboxLabelText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: "2.7rem",
  letterSpacing: "-0.005em",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.6rem",
    lineHeight: "2.2rem",
  },
}));

const CheckboxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const DetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "3rem",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    gap: "3.2rem",
  },
}));

const ContactButton = styled(Button)(({ theme }) => ({
  fontFamily: "DIN-Medium",
  border: `1.5px solid transparent`,
  "&:hover": {
    "&.MuiButton-root": {
      backgroundColor: "#fff",
      border: `1.5px solid ${theme.palette.secondary.main}`,
    },
    "& > *": {
      color: theme.palette.secondary.main,
    },
    "& svg path": {
      fill: theme.palette.secondary.main,
    },
  },
  [theme.breakpoints.down("tablet")]: {
    padding: 8,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "DIN-Medium",
  fontSize: "1.6rem",
  lineHeight: "2.2rem",
  letterSpacing: "-0.005em",
  [theme.breakpoints.down("tablet")]: {
    fontSize: "1.4rem",
    lineHeight: "1.9rem",
  },
}));

const CheckboxWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  cursor: "pointer",
  gap: "1rem",
  marginTop: "1rem",
}));

const CheckboxLabelField = styled("label")(({ theme }) => ({
  fontSize: "1.4rem",
  lineHeight: "1.9rem",
  fontWeight: "400",
  color: "#000",
  cursor: "pointer",
}));

const Checkbox = styled("input")(({ theme }) => ({
  cursor: "pointer",
}));

const initialState = {
  name: "",
  email: "",
  mobile: "",
  query: "",
  checkbox: [],
};

const EmailForm = ({ enquiryPage = false }) => {
  const [formDetails, setFormDetails] = useState(initialState);

  const handleChangeText = (event, key) => {
    setFormDetails({ ...formDetails, [key]: event.target.value });
  };

  // const handleCheckboxChange = (event, checked) => {
  //   if (!checked) {
  //     setFormDetails({
  //       ...formDetails,
  //       checkbox: formDetails.checkbox.filter(
  //         (value) => value != event.target.value
  //       ),
  //     });
  //   } else {
  //     const data = formDetails.checkbox;
  //     data.push(event.target.value);
  //     setFormDetails({
  //       ...formDetails,
  //       checkbox: data,
  //     });
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("event", e.target);

    emailjs
      .sendForm(
        "service_wlho8bs",
        "template_ddat3fh",
        e.target,
        "Rd7z5eDPxewSWjOxf"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <Container enquiryPage={enquiryPage} onSubmit={(e) => handleSubmit(e)}>
      <InputField
        required
        variant="standard"
        name="name"
        label="Full Name"
        type="text"
        placeholder="Ajay Raj"
        value={formDetails.name}
        onChange={(e) => handleChangeText(e, "name")}
        InputLabelProps={{ shrink: true }}
      />
      <DetailsContainer>
        <InputField
          required
          variant="standard"
          name="email"
          label="Email ID"
          type="email"
          placeholder="ajayraj@gmail.com"
          value={formDetails.email}
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={(e) => handleChangeText(e, "email")}
        />
        <InputField
          required
          variant="standard"
          name="mobile"
          label="Mobile"
          type="number"
          fullWidth
          value={formDetails.mobile}
          placeholder="+91 85453 21969"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => handleChangeText(e, "mobile")}
        />
      </DetailsContainer>
      <div>
        <CheckboxLabelText>Inquiry For *</CheckboxLabelText>
        <CheckboxContainer>
          {checkboxConfig.map((item) => (
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                id={item.key}
                name={item.name}
                value={item.label}
              />
              <CheckboxLabelField for={item.key}>
                {item.label}
              </CheckboxLabelField>
            </CheckboxWrapper>
            // <CheckboxLabel
            //   control={
            //     <MuiCheckbox
            //       value={item.label}
            //       onChange={handleCheckboxChange}
            //     />
            //   }
            //   {...item}
            // />
          ))}
        </CheckboxContainer>
      </div>

      <InputField
        variant="standard"
        name="query"
        multiline
        label="Your Message"
        placeholder="Describe your query here..."
        InputLabelProps={{ shrink: true }}
        value={formDetails.query}
        onChange={(e) => handleChangeText(e, "query")}
      />

      <ContactButton
        variant="contained"
        color="secondary"
        fullWidth
        type="submit"
      >
        <Text>Book a Call</Text>
      </ContactButton>
    </Container>
  );
};

export default EmailForm;

const inputConfig = [
  {
    key: 0,
    name: "name",
    label: "Full Name",
    type: "text",
    placeholder: "Ajay Raj",
    fullWidth: true,
    required: true,
  },
  {
    key: 1,
    name: "email",
    type: "email",
    label: "Email ID",
    placeholder: "ajayraj@gmail.com",
    fullWidth: false,
    required: true,
  },
  {
    key: 2,
    name: "mobile",
    type: "number",
    label: "Mobile",
    placeholder: "+91 85453 21969",
    fullWidth: false,
    required: true,
  },
];

const checkboxConfig = [
  {
    key: "rooftop",
    name: "inquiry",
    label: "Rooftop Solar",
  },
  {
    key: "commercial",
    name: "inquiry",
    label: "Commercial Solar Plant",
  },
  {
    key: "epc",
    name: "inquiry",
    label: "Solar EPC work",
  },
  {
    key: "panel",
    name: "inquiry",
    label: "Solar Panels",
  },
];
